import React, { useEffect, useReducer, useState } from "react";
import { Input, Space, Row, Col, Button, Popover, Checkbox, Tag, Table, Form, Spin, Alert, Radio, DatePicker } from "antd/es";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import {
  DIMENSIONS,
  regulationMap,
  FILTER_TYPE,
  REPORT_TYPES,
  TIME,
  COUNTRY_CODES,
  OLD_VERSION,
  NO_DATA
} from "../../../utils/constants";
import moment from "moment";
import { useAnalyticsMetaData, useGetAnalyticsData, useSaveAndRunJob, useUpdateJob } from "../../../data/hooks/analytics";
import Style from "./analytics.module.scss";
import Styles from "../premiumReports.module.scss";
import { downloadCSV, TIME_FILTERS_WITH_START_END_DATES, transformFilters, reverseTimeFilter, getTimePeriodLabel, getSiteUrl } from "../../utils";

import FilterContent from "./filterContent";
import DimensionMetric from "./dimensionMetric";
import ScheduleReportModal from "./scheduleReportModal";
import { useHistory, Link } from "react-router-dom";
import {
  INITIAL_STATE,
  ACTION_TYPES,
  reducer
} from "./state";
import { formatNumber } from "../utils";
import { usePremiumSitesList } from "../../../data/hooks/sites";
import TEXT from "../text";

const DEFAULT_FILTERS = [
  { label: "Regulation", type: FILTER_TYPE.REGULATION, values: [] },
  { label: "Time", type: FILTER_TYPE.TIME, values: [] },
  { label: "Device Type", type: FILTER_TYPE.DEVICE_TYPE, values: [] },
  { label: "OS", type: FILTER_TYPE.OS, values: [] },
  { label: "Page", type: FILTER_TYPE.PAGE_FORMAT, values: [] },
  { label: "Country", type: FILTER_TYPE.COUNTRY, values: [] },
  { label: "Granularity", type: FILTER_TYPE.GRANULARITY, values: [] },
  { label: "Browser", type: FILTER_TYPE.BROWSER, values: [] },
  { label: "Property", type: FILTER_TYPE.PROPERTY, values: [] }
];

const Analytics = ({ pCode, propertyId, jobData }) => {
  const history = useHistory();
  const { location } = history;

  const [form] = Form.useForm();

  const isEditMode = jobData && jobData.data;
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTERS);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [timeMissingError, setTimeMissingError] = useState(null);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  
  const { data: dimensionAndMetricsData, isLoading: isMetadataLoading, isError: isMetadataError } = useAnalyticsMetaData();
  const { refetch, data: analyticsData, isFetching: analyticsLoading, error: analyticsError } = useGetAnalyticsData(pCode, state.payload, false);
  const { refetch: refetchSaveAndRunJob, data: saveAndRunJobData, isFetching: saveAndRunJobLoading } = useSaveAndRunJob(pCode, state.saveRunJobPayload, false);
  const { refetch: refetchUpdateJob, data: updateData, isFetching: updateJobLoading } = useUpdateJob(pCode, state.saveRunJobPayload, false, jobData && jobData.data && jobData.data.id);
  const { isLoading: isSitesLoading, data: premiumSites } = usePremiumSitesList(pCode);

  console.log('analyticsError', analyticsError);

  useEffect(() => {
    refetch();
  }, [state.payload]);

  useEffect(() => {
    if (isEditMode) {
      refetchUpdateJob();
    } else {
      refetchSaveAndRunJob();
    }
  }, [state.saveRunJobPayload]);

  useEffect(() => {
    if (jobData && jobData.data) {
      const data = jobData.data;
      const timeFilter = data.query.filters.find(el => el.key === "startDate");
      const timeFilter2 = data.query.filters.find(el => el.key === "endDate");
      const timeFilterLabel = reverseTimeFilter(timeFilter.values[0], timeFilter2.values[0]);

      console.log('timeFilterLabel', timeFilterLabel);

      const filters = data.query.filters.map(filter => {
        const filterData = selectedFilters.find(el => el.type === filter.key);

        if (filter.key === "startDate" || filter.key === "endDate" || filter.key === "propertyId") {
          return;
        }

        return {
          ...filterData,
          values: filter.values
        };
      }).filter(Boolean);

      const newFilters = [...filters, {
        label: "Time",
        type: FILTER_TYPE.TIME,
        values: [timeFilterLabel]
      }];
      const prevFilters = [...newFilters, ...DEFAULT_FILTERS.filter(el => el.type !== FILTER_TYPE.TIME)];
      setSelectedFilters(prevFilters);
      setSelectedDimensions(data.query.dimensions);
      setSelectedMetrics(data.query.metrics);

      dispatch({ type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: data.query.granularity });
      dispatch({ type: ACTION_TYPES.SET_REPORT_NAME, payload: data.name });
      dispatch({ type: ACTION_TYPES.SET_CUSTOM_DATE, payload: [timeFilter.values[0], timeFilter2.values[0]] });
      dispatch({ type: ACTION_TYPES.SET_CUSTOM_DATE_STRING, payload: [timeFilter.values[0], timeFilter2.values[0]] });

      // Update schedule report modal data
      form.setFieldValue("name", data.name);

      form.setFieldsValue({
        frequency: data.scheduleInfo && data.scheduleInfo.frequency ? data.scheduleInfo.frequency : '',
        timePeriod: data.query.timePeriod && data.query.timePeriod.type ? data.query.timePeriod.type : '',
        startDate: data.scheduleInfo && data.scheduleInfo.startDate ? moment(data.scheduleInfo.startDate) : null,
        endDate: data.scheduleInfo && data.scheduleInfo.endDate ? moment(data.scheduleInfo.endDate) : null,
        time: data.scheduleInfo && data.scheduleInfo.time ? moment(data.scheduleInfo.time, "H:mm") : null,
        recipients: data.recipients ? data.recipients.map(el => ({ first: el })) : []
      });
    }
  }, [jobData]);

  // When filter have time, we need to push Time in selectedDimensions
  useEffect(() => {
    const timeFilter = selectedFilters.find(el => el.type === "time");

    if (timeFilter && timeFilter.values.length > 0) {
      setTimeMissingError(null);

      const dimensionsWithTime = selectedDimensions.find(el => el === "Time");
      if (!dimensionsWithTime) {
        setSelectedDimensions(prevState => (prevState.includes("time") ? prevState : [...prevState, "time"]));
      }

      const timeValue = timeFilter.values[0];

      if (['week', 'month'].includes(state.granularity)) {
        return;
      }

      switch (timeValue) {
        case "":
        case "This Week":
        case TIME.MONTH_TILL_DATE:
        case TIME.LAST_7_DAYS:
        case TIME.LAST_30_DAYS:
        case TIME.LAST_3_MONTHS: {
          dispatch({ type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: "week" });
          break;
        }
        case TIME.CUSTOM_DATE: {
          if (state.customDate && state.customDate.length > 0) {
            const startDate = state.customDate[0];
            const endDate = state.customDate[1];
            const diffDays = moment(endDate).diff(moment(startDate), "days");
            const diffMonths = moment(endDate).diff(moment(startDate), "months");

            if (diffDays < 13) {
              // Custom date range less than 13 days
              dispatch({ type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: "day" });
            } else if (diffMonths > 3) {
              // Custom date range more than 3 months
              dispatch({ type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: "month" });
            } else {
              // Custom date range less than 3 months
              dispatch({ type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: "week" });
            }
          }
          break;
        }
        default: {
          // Handle cases where time period is missing or unexpected
          if (!timeValue || timeValue === "" || state.customDate.length === 0) {
            dispatch({ type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: "day" });
          }
          break;
        }
      }
    }
  }, [selectedFilters, state.customDate, state.customDateString]);

  const handleChange = (tag, checked) => {
    const nextgranularity = checked ? tag : null;
    dispatch({type: ACTION_TYPES.SET_SELECTED_GRANULARITY, payload: nextgranularity});
  };

  const handleFiltersChange = (e, type) => {
    const { checked, value } = e.target;
  
    setSelectedFilters((prevState) =>
      prevState.map((dim) => {
        if (dim.type === type) {
          let newValues;
          if (type === "time") {
            // For "time" type, only allow a single selection
            newValues = checked ? [value] : [];
          } else {
            // For other types, handle it normally
            newValues = checked ? [...dim.values, value] : dim.values.filter((v) => v !== value);
          }
  
          return {
            ...dim,
            values: newValues,
          };
        }
        return dim;
      })
    );

    if (type == 'time') {
      dispatch({type: ACTION_TYPES.SET_CUSTOM_DATE, payload: []});
      dispatch({type: ACTION_TYPES.SET_CUSTOM_DATE_STRING, payload: ''});
    }
  };

  const handleMetricChange = e => {
    const { checked, value } = e.target;

    setSelectedMetrics(prevState => {
      if (checked) {
        // Add the metric if checked
        return [...prevState, value];
      } else {
        // Remove the metric if unchecked
        return prevState.filter(metric => metric !== value);
      }
    });
  };

  const handleDimensionChange = e => {
    const { checked, value } = e.target;

    setSelectedDimensions(prevState => {
      if (checked) {
        // Add the metric if checked
        return [...prevState, value];
      } else {
        // Remove the metric if unchecked
        return prevState.filter(metric => metric !== value);
      }
    });
  };

  const dimensions = () => isMetadataLoading ? (
    <Spin />
  ) : (
    <DimensionMetric
      data={dimensionAndMetricsData.dimensions}
      selectedValues={selectedDimensions}
      handleChange={handleDimensionChange}
      type={"dimension"}
    />
  );

  const filterContent = (
    <FilterContent
      granularity={state.granularity}
      selectedFilters={selectedFilters}
      selectedDimensions={selectedDimensions}
      handleFiltersChange={handleFiltersChange}
      handleChange={handleChange}
      customDate={state.customDate}
      setCustomDate={(payload) => dispatch({ type: ACTION_TYPES.SET_CUSTOM_DATE, payload })}
      setCustomDateString={(payload) => dispatch({ type: ACTION_TYPES.SET_CUSTOM_DATE_STRING, payload })}
      timeMissingError={timeMissingError}
    />
  );

  const metricContent = () => isMetadataLoading ? (
    <Spin />
  ) : (
    <DimensionMetric
      data={dimensionAndMetricsData.metrics}
      selectedValues={selectedMetrics}
      handleChange={handleMetricChange}
      type={"metric"}
    />
  );
  const handleRemoveMetric = value => {
    // dispatch({type: ACTION_TYPES.SET_SELECTED_METRICS, payload: })
    setSelectedMetrics(prevState => prevState.filter(dim => dim !== value));
  };

  const handleRemoveDimension = value => {
    setSelectedDimensions(prevState => prevState.filter(dim => dim !== value));
  };

  const handleRemoveFilter = (value, type) => {
    const filters = selectedFilters.map(filter => {
      if (filter.type === type) {
        const values = filter.values.filter(v => v !== value);
        filter.values = [...values];
      }
      return filter;
    });
    if (type === 'time') {
      dispatch({type: ACTION_TYPES.SET_CUSTOM_DATE, payload: []});
      dispatch({type: ACTION_TYPES.SET_CUSTOM_DATE_STRING, payload: ''});
    }
    setSelectedFilters(filters);
  };

  const saveAndRunJob = async () => {
    setErrorMessage(null);
    if (!state.reportName) {
      setErrorMessage("Please enter report name");
      return;
    }
    const timeWithDates = selectedFilters
      .find(el => el.type === "time")
      .values.map(time => TIME_FILTERS_WITH_START_END_DATES.find(el => el.label == time));
     
      
    const isCustomDate = selectedFilters.find(el => el.type === "time").values[0] === TIME.CUSTOM_DATE;

    if (timeWithDates && timeWithDates[0]) {
      const payload = {
        name: state.reportName,
        query: {
          filters: [
            ...transformFilters(selectedFilters),
            {
              key: "startDate",
              operator: "gteq",
              values: [isCustomDate ? state.customDateString[0]: timeWithDates[0].startDate]
            },
            {
              key: "endDate",
              operator: "lteq",
              values: [isCustomDate ? state.customDateString[1]: timeWithDates[0].endDate]
            },
            ...(propertyId
              ? [
                  {
                    key: "propertyId",
                    operator: "in",
                    values: propertyId
                  }
                ]
              : [])
          ],
          dimensions: selectedDimensions.filter(dimension => dimension !== "time"),
          metrics: selectedMetrics,
          granularity: state.granularity
        }
      };

      payload.query.timePeriod = {
        type: getTimePeriodLabel(selectedFilters.find(el => el.type === "time").values[0]),
        range: isCustomDate ? [moment(state.customDate[0]).utc().format("yyyy-MM-DD"), moment(state.customDate[1]).utc().format("yyyy-MM-DD")]: []
      };

      if (jobData && jobData.data && jobData.data.scheduleInfo) {
        payload.scheduleInfo = jobData.data.scheduleInfo;
      }

      if (jobData && jobData.data && jobData.data.recipients) {
        payload.recipients = jobData.data.recipients;
      }

      if (jobData && jobData.data && jobData.data.query.timePeriod) {
        payload.query.timePeriod = jobData.data.query.timePeriod;
      }

      if (jobData && jobData.data && jobData.data.query.timePeriod && jobData.data.query.timePeriod.range) {
        payload.query.timePeriod.range = jobData.data.query.timePeriod.range;
      }
      dispatch({ type: ACTION_TYPES.SET_SAVE_RUN_JOB_PAYLOAD, payload });
      dispatch({ type: ACTION_TYPES.SET_REPORT_TYPE, payload: REPORT_TYPES.ON_DEMAND });
    }
  };

  const runReport = async () => {
    setErrorMessage(null);
    if (selectedMetrics.length <= 0 || selectedFilters.find(el => el.type === "time").values.length <= 0 || !state.granularity) {
      setErrorMessage('Please select atleast Metric, Time and Granularity');
      return;
    }
    const timeWithDates = selectedFilters
      .find(el => el.type === "time")
      .values.map(time => TIME_FILTERS_WITH_START_END_DATES.find(el => el.label == time));

    const dimensionFilters = [
      ...selectedFilters
        .filter(filter => filter.values.length > 0) // Only consider filters with values
        .filter(filter => filter.type !== "time")
        .map(filter => ({
          key: filter.type,
          operator: "in",
          values: filter.values
        }))
    ];

    const isCustomDate = selectedFilters.find(el => el.type === "time").values[0] === TIME.CUSTOM_DATE;

    const payload = {
      queries: [
      {
        id: "dimensions_metrics",
        dimensions: selectedDimensions.filter(dimension => dimension !== "time"),
        metrics: selectedMetrics,
        granularity: state.granularity
      }
      ],
      filters: [
      {
        key: "startDate",
        operator: "gteq",
        values: [isCustomDate ? state.customDateString[0]: timeWithDates[0].startDate]
      },
      {
        key: "endDate",
        operator: "lteq",
        values: [isCustomDate ? state.customDateString[1]: timeWithDates[0].endDate]
      },
      ...(propertyId ? [{
        key: "propertyId",
        operator: "in",
        values: propertyId
      }] : []),
      ...dimensionFilters
      ]
    };
    dispatch({type: ACTION_TYPES.SET_PAYLOAD, payload});
   };

  const onFinishSchedule = async values => {
    await form.validateFields();
    const { name, frequency, startDate, endDate, time, recipients } = values;

    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    const timeWithDates = selectedFilters
      .find(el => el.type === "time")
      .values.map(time => TIME_FILTERS_WITH_START_END_DATES.find(el => el.label == time));
    
    const isCustomDate = selectedFilters.find(el => el.type === "time").values[0] === TIME.CUSTOM_DATE;
    
    const payload = {
      name: name,
      scheduleInfo: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        frequency,
        time: moment(time).format("H:mm")
      },
      recipients: recipients && recipients.length > 0 ? recipients.map(el => el.first): [],
      query: {
        filters: [
          ...transformFilters(selectedFilters),
          {
            key: "startDate",
            operator: "gteq",
            values: [isCustomDate ? state.customDateString[0]: timeWithDates[0].startDate]
          },
          {
            key: "endDate",
            operator: "lteq",
            values: [isCustomDate ? state.customDateString[1]: timeWithDates[0].endDate]
          },
          ...(propertyId ? [{
            key: "propertyId",
            operator: "in",
            values: propertyId
          }] : []),
        ],
        dimensions: selectedDimensions.filter(dimension => dimension !== "time"),
        metrics: selectedMetrics,
        granularity: state.granularity
      }
    };
    payload.query.timePeriod = {
      type: getTimePeriodLabel(selectedFilters.find(el => el.type === "time").values[0]),
      range: isCustomDate ? [moment(state.customDate[0]).utc().format("yyyy-MM-DD"), moment(state.customDate[1]).utc().format("yyyy-MM-DD")]: []
    };
    dispatch({type: ACTION_TYPES.SET_SAVE_RUN_JOB_PAYLOAD, payload});
    dispatch({type: ACTION_TYPES.TOGGLE_SCHEDULE_MODAL});
    dispatch({ type: ACTION_TYPES.SET_REPORT_TYPE, payload: REPORT_TYPES.SCHEDULED });
  };

  const onFinishFailed = errorInfo => {
  };

  const onRunReport = () => {
    runReport();
    const columns = [
      {
        title: () => {
          if (state.granularity === "week") return "Week";
          if (state.granularity === "month") return "Month";
          return "Period"
        },
        dataIndex: "period",
        key: "period",
        render: (text, record) => {
          if (state.granularity === "week") return record['week'];
          if (state.granularity === "month") return record['month'];
          return record['date'];
        }
      }
    ];
    if (selectedDimensions.includes(FILTER_TYPE.REGULATION)) {
      columns.push({
        title: "Regulation",
        dataIndex: "regulation",
        key: "regulation",
        render: text => {
          return regulationMap[text];
        }
      });
    }
    if (selectedDimensions.includes(FILTER_TYPE.COUNTRY)) {
      columns.push({
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 200,
        render: text => {
          const countryObj = COUNTRY_CODES.find(option => option.value === text);
          return countryObj ? countryObj.label : text;
        }
      });
    }
    if (selectedDimensions.includes(FILTER_TYPE.PAGE_FORMAT)) {
      columns.push({
        title: "Page Format",
        dataIndex: "pageFormat",
        key: "pageFormat",
        render: text => {
          if (!text) return NO_DATA; 
          return text === OLD_VERSION ? TEXT.OLD_VERSION_MESSAGE : text;
        }
      });
    }
    if (selectedDimensions.includes(FILTER_TYPE.DEVICE_TYPE)) {
      columns.push({
        title: "Device Type",
        dataIndex: "deviceType",
        key: "deviceType",
        render: text => {
          if (!text) return NO_DATA; 
          return text === OLD_VERSION ? TEXT.OLD_VERSION_MESSAGE : text;
        }
      });
    }
    if (selectedDimensions.includes(FILTER_TYPE.OS)) {
      columns.push({
        title: "Operating System",
        dataIndex: "os",
        key: "os",
        render: text => {
          if (!text) return NO_DATA; 
          return text;
        }
      });
    }

    if (selectedDimensions.includes(FILTER_TYPE.BROWSER)) {
      columns.push({
        title: "Browser",
        dataIndex: "browser",
        key: "browser",
        render: text => {
          if (!text) return NO_DATA; 
          return text;
        }
      });
    }

    if (selectedDimensions.includes(FILTER_TYPE.PROPERTY)) {
      columns.push({
        title: "Property",
        dataIndex: "propertyId",
        key: "propertyId",
        render: text => {
          return premiumSites && premiumSites.properties ? getSiteUrl(text, premiumSites) : text;
        }
      });
    }
    selectedMetrics.forEach(metric => {
      const metricData = dimensionAndMetricsData.metrics.find(el => el.fieldName === metric);
      columns.push({
        title: isMetadataLoading ? <Spin /> : metricData.displayName || metric,
        dataIndex: metric,
        key: metric,
        render: text => {
          if (metricData.fieldName.toLowerCase().includes("percent")) {
            return `${Number(text).toFixed(2)}%`;
          } else {
            return formatNumber(text);
          }
        }
      });
    });
    dispatch({ type: ACTION_TYPES.SET_COLUMNS, payload: columns });
  };

  const getTableData = (data = []) => data[0].results;

  const exportData = (data) => {
    downloadCSV(data);
  }

  const getFilterTags = (filter, value) => {
    if (filter.type === FILTER_TYPE.TIME) {
      return (
        <>
          {value == TIME.CUSTOM_DATE ? (
            <>
              {state.customDateString ? (
                <>
                  {"Start Date " + state.customDateString[0]} - {"End Date " + state.customDateString[1]}{" "}
                  {state.granularity ? <>: {state.granularity.toUpperCase()}</> : null}
                </>
              ) : null}
            </>
          ) : (
            <>
              {value} {state.granularity ? <>: {state.granularity.toUpperCase()}</> : null}
            </>
          )}
        </>
      );
    } else if (filter.type === FILTER_TYPE.REGULATION) {
      return <>{regulationMap[value]}</>;
    } else {
      return <>{value}</>;
    }
  };

  const tableData = analyticsData && analyticsData.length > 0 ? getTableData(analyticsData) : [];

  return (
    <div className={Style.analytics_wrapper}>
      <Row gutter={[10, 25]}>
        <Col span={24}>
          <div className={Style.flex + " " + Style.justify_between}>
            <p className={Style.heading}> Create your Custom Report </p>

            <Space align="horizontal" size="middle">
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                disabled={tableData && tableData.length <= 0}
                onClick={() => exportData(tableData)}
              >
                Export Report
              </Button>

              <Button onClick={() => dispatch({ type: ACTION_TYPES.TOGGLE_SCHEDULE_MODAL, payload: true })}>
                Schedule Report
              </Button>
            </Space>
          </div>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: "16px" }}>
        <Col span={6}>
          <Input
            placeholder="Enter Report Name"
            value={state.reportName}
            onChange={e => dispatch({ type: ACTION_TYPES.SET_REPORT_NAME, payload: e.target.value })}
          />
        </Col>
        <Col span={16}>
          <Row gutter={16}>
            <Col span={4}>
              <Button onClick={saveAndRunJob} loading={saveAndRunJobLoading || updateJobLoading}>
                {isEditMode ? "Edit Report" : "Save Report"}
              </Button>
            </Col>

            <Col span={4}>
              <Button
                onClick={() => {
                  onRunReport();
                }}
                loading={analyticsLoading}
              >
                Run Report
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {analyticsError && typeof analyticsError === 'string' && analyticsError.includes('504 Gateway Timeout ERROR') ? (
        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <Alert
              message={'Please try with few dimensions and metrics.'}
              closable
              type="error"
            />
          </Col>
        </Row>
      ) : null}

      {saveAndRunJobData && saveAndRunJobData.message ? (
        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <Alert
              message={saveAndRunJobData.message}
              closable
              type="success"
              action={
                <>
                  {state.reportType == REPORT_TYPES.SCHEDULED ? (
                    <>
                      <Link to={`/protect/p-${pCode}/reports/view-reports?report_type=scheduled`}>Go to reports</Link>
                    </>
                  ) : (
                    <Link to={`/protect/p-${pCode}/reports/view-reports?report_type=on_demand`}>Go to reports</Link>
                  )}
                </>
              }
            />
          </Col>
        </Row>
      ) : null}

      {errorMessage ? (
        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <Alert message={errorMessage} closable type="error" />
          </Col>
        </Row>
      ) : null}

      <div className={Style.popover}>
        <div className={Style.flex + " " + Style.flex_direction_column}>
          <div className={Style.popover_container}>
            <Popover
              destroyTooltipOnHide
              overlayStyle={{ display: "flex", height: "400px", overflow: "scroll", padding: 0 }}
              content={dimensions}
              placement="bottomLeft"
              showArrow={false}
              trigger="click"
              open={state.dimensionOpen}
              onOpenChange={open => dispatch({ type: ACTION_TYPES.TOGGLE_DIMENSION_OPEN, payload: open })}
            >
              <Button type="primary" icon={<PlusOutlined />} style={{ width: "160px", textAlign: "left" }}>
                Add Dimension
              </Button>
            </Popover>

            <div className={Style.tags}>
              {selectedDimensions.map(dimension => (
                <Tag
                  key={dimension}
                  color="blue"
                  closable
                  onClose={() => handleRemoveDimension(dimension)}
                  className={Style.popover_tag}
                >
                  {DIMENSIONS.find(d => d.name === dimension) ? DIMENSIONS.find(d => d.name === dimension).label : ""}
                </Tag>
              ))}
            </div>
          </div>

          <div style={{ marginTop: "16px" }}>
            <div className={Style.popover_container}>
              <Popover
                destroyTooltipOnHide
                overlayStyle={{ display: "flex", padding: 0, width: "200px" }}
                content={filterContent}
                placement="bottom"
                showArrow={false}
                trigger="click"
                open={state.filterOpen}
                onOpenChange={open => {
                  if (!open) {
                    const timeFilter = selectedFilters.find(el => el.type === "time");
                    if (timeFilter.values.length <= 0) {
                      setTimeMissingError("Please select Time filter");
                      return;
                    }
                  }
                  dispatch({ type: ACTION_TYPES.TOGGLE_FILTER_OPEN, payload: open });
                }}
              >
                <Button type="primary" icon={<PlusOutlined />} style={{ width: "160px", textAlign: "left" }}>
                  Add Filter
                </Button>
              </Popover>

              <div className={Style.tags}>
                {selectedFilters.map((filter, index) => (
                  <>
                    {filter.values.length > 0 ? (
                      <div key={index}>
                        <span className={Style.tag_label}>{filter.label}</span>
                        {filter.values.map(value => (
                          <Tag
                            key={value}
                            color="blue"
                            closable
                            onClose={() => handleRemoveFilter(value, filter.type)}
                            className={Style.popover_tag}
                          >
                            {getFilterTags(filter, value)}
                          </Tag>
                        ))}
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </div>

          <div style={{ marginTop: "16px" }}>
            <div className={Style.popover_container}>
              <Popover
                destroyTooltipOnHide
                overlayStyle={{ display: "flex", padding: 0 }}
                content={metricContent}
                placement="bottom"
                showArrow={false}
                trigger="click"
                open={state.metricOpen}
                onOpenChange={open => dispatch({ type: ACTION_TYPES.TOGGLE_METRIC_OPEN, payload: open })}
              >
                <Button type="primary" icon={<PlusOutlined />} style={{ width: "160px", textAlign: "left" }}>
                  Add Metric
                </Button>
              </Popover>

              <div className={Style.tags}>
                {selectedMetrics.map(metric => (
                  <Tag
                    key={metric}
                    color="blue"
                    closable
                    onClose={() => handleRemoveMetric(metric)}
                    className={Style.popover_tag}
                  >
                    {isMetadataLoading ? (
                      <Spin />
                    ) : (
                      dimensionAndMetricsData.metrics.find(el => el.fieldName == metric).displayName
                    )}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "16px" }}>
          {analyticsData && analyticsData.length > 0 ? (
            <Table
              columns={state.columns}
              dataSource={tableData}
              rowClassName={(record, index) => (index % 2 === 0 ? Styles.tableRowDark : Styles.tableRowLight)}
              loading={analyticsLoading}
            />
          ) : null}
        </div>
      </div>

      <ScheduleReportModal
        isScheduleModalOpen={state.isScheduleModalOpen}
        setIsScheduleModalOpen={() =>
          dispatch({ type: ACTION_TYPES.TOGGLE_SCHEDULE_MODAL, payload: state.isScheduleModalOpen })
        }
        onFinish={onFinishSchedule}
        onFinishFailed={onFinishFailed}
        form={form}
      />
    </div>
  );
};

export default Analytics;

/* eslint-disable import/prefer-default-export */
// @flow
import React from 'react';
import type { iTheme, iThemesTableData } from '../interfaces/themes';

type buildTableDataProps = {
  actions: Array<any>,
  themes: Array<iTheme>,
  deleteTheme: Function
}


export const mapRegulationValue = (regulation) => {
  let regulationValue = '';
  if (regulation && typeof regulation === 'object') {
    const regulationJoin = regulation.join();

    switch (regulationJoin) {
      case 'GDPR':
        regulationValue = 'EU regulation';
        break;
      case 'USP':
        regulationValue = 'US regulation';
        break;
      default:
        regulationValue = 'EU & US regulation';
        break;
    }
  }

  return regulationValue;
};

export const encode = (data) => {
  const encodedData = data.includes(',') ? data.replace(/,/gi, '%2C') : data;
  return encodedData;
};

export const decode = (data) => {
  const decodedData = data.replace(/%2C/gi, ',');
  return decodedData;
};

export const formatCustomLinks = (string) => {
  const elements = string.split(',');
  return elements.map((element) => {
    const formattedElement = decode(element);
    try {
      return ({
        text: formattedElement.match(/[^[\]]+(?=])/g)[0],
        url: formattedElement.match(/[^(\]]+(?=\))/g)[0]
      });
    } catch(e) {
      console.error('formatCustomLinks', 'Error in regex for text and url', e);
      return ({
        text: '',
        url: ''
      });
    }
  });
};


export function buildTableData({
  actions,
  themes,
  displayDeleteThemeModal,
  history
}: buildTableDataProps): Array<iThemesTableData> {
  const data = themes.map(theme => ({
    default: theme.defaultTheme,
    id: theme.themeId,
    name: theme.themeName,
    regulation: (`${mapRegulationValue(theme.privacyModes)}`),
    actions: actions.map((action) => {
      const props = Object.assign({}, action);
      props.dataTest = `${props.dataTest}_${theme.themeId}`;
      const href = props.href;
      delete props.component;
      delete props.href;
      const deleteAction = 'Delete';
      if (theme.defaultTheme) return null;
      if (action.title === deleteAction) {
        delete props.dataTest;
      }
      return (
        <action.component
          onClick={() => {
            if(action.dataTest === 'themes_edit') {
              history.push(`${href}/${theme.themeId}`);
            } else {
              displayDeleteThemeModal(theme.themeId, theme.themeName)
            }
          }}
          key={`${action.href}/${theme.themeId}`}
          {...props}
        />
      );
    })
  }));

  return data;
}

export function formatDataToSubmit(data: any): Object {
  const formData = Object.assign({}, data);
  const linkFieldsUsp = ['uspDeleteDataLink', 'uspAccessDataLink', 'uspPrivacyPolicyLink'];
  linkFieldsUsp.forEach((linkField) => {
    if (formData[linkField]) {
      formData[linkField] = /^(?:http(s)?:\/\/)/i.test(formData[linkField])
        ? formData[linkField]
        : `http://${formData[linkField]}`;
    }
  });
  if (formData.uxInitialScreenCustomLinks
    && formData.uxInitialScreenCustomLinks.length
  ) {
    let nextValue = '';
    formData.uxInitialScreenCustomLinks.forEach((link) => {
      const text = encode(link.text);
      const url = encode(link.url);
      const linkFixedUrl = /^(?:http(s)?:\/\/)/i.test(url) ? url : `http://${url}`;
      if (link.text) nextValue += `[${text}](${linkFixedUrl}),`;
    });
    formData.uxInitialScreenCustomLinks = nextValue.substr(0, nextValue.length - 1);
  } else {
    delete formData.uxInitialScreenCustomLinks;
  }
  if (
    'purposeIds' in formData
    && formData.purposeIds.length
  ) {
    const nextPurposeIds = [];
    const nextPurposeLegitimateIds = [];
    formData.purposeIds.forEach((purpose) => {
      if (purpose.id) nextPurposeIds.push(parseInt(purpose.id, 10));
      if (!(purpose.legitimate === 'false')) nextPurposeLegitimateIds.push(parseInt(purpose.id, 10));
    });
    formData.purposeIds = nextPurposeIds;
    formData.purposeLegitimateIds = nextPurposeLegitimateIds;
  }

  formData.uxShowRejectButton = formData.uxShowRejectButton === 'true';

  Object.keys(formData).forEach((key) => {
    if (formData[key] === null) delete formData[key];
    if (typeof formData[key] === 'string' && formData[key] === ''
    && (key.includes('ux') && key.includes('Color'))) delete formData[key];
    if (
      (key === 'purposeIds' || key === 'purposeLegitimateIds')
      && !(formData[key].length)
    ) {
      delete formData[key];
    }

    return false;
  });

  return formData;
}

// @flow
import './styles.scss';

import React, { useEffect, useState } from 'react';
import { faAngleDown, faAngleRight } from '@fortawesome/fontawesome-pro-light';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'antd/es/button';
import Collapse from 'antd/es/collapse';
import Divider from 'antd/es/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'antd/es/table';
import { Modal } from 'antd/es';
import { ALERT_IDENTIFIERS, GPP_CMP_VERSION, LATEST_CMP_VERSION, REGULATION_MODES_V2 } from '../../utils/constants';
import { BACK_TO_ALL_PROPERTIES_LABEL, CONSENT_LOCATIONS_MAPPING, PAGE_TITLE, TYPES_FILER } from './constants';

import Alert from '../../components/ui/alert';
import BulkUpdateFilter from './lib/filter';
import BulkUpdateForm from './lib/form';
import Card from '../../components/ui/card';
import ContentHeader from '../../components/ui/content-header';
import { NotFoundPage } from '../not-found';
import Spin from '../../components/ui/spin';
import Summary from './lib/summary';
import { fetchAllThemes } from '../../store/duck/themes';
import { fetchCMPVersionsV2 } from '../../store/duck/versionsV2';
import { getSitesInitData } from '../../store/duck/sites';
import { remove } from '../../store/duck/alerts';
import { tableColumns } from './utils';
import useBulkForm from './lib/useBulkForm';
import withNavbar from '../../components/hoc/with-navbar';
import useGBCPurposes from '../../components/forms/gbc/useGBCPurposes';
import { isGPPSupported } from '../../utils/property';

const { CardContent } = Card;

const BulkUpdatePage = () => {
  const history = useHistory();
  const { page } = useParams();
  const dispatch = useDispatch();
  const pagesAllowed = ['details', 'overview'];

  const sites = useSelector(state => state.sites);
  const themes = useSelector(state => state.themes);
  const cmpVersionV2 = useSelector(state => state.cmpVersionV2);

  const app = useSelector(state => state.app);

  const [filteredSites, setFilteredSites] = useState<Object[] | null>([]);
  const [selectedSites, setSelectedSites] = useState<Object[] | null>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Object[] | null>([]);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<Object | null>({});
  const [selectedType, setSelectedType] = useState<number>(null);
  const [selectedRegulation, setSelectedRegulation] = useState<number>(null);
  const [activeKey, setActiveKey] = useState<string[]>(['1']); // eslint-disable-line
  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterFields, filterHandleChange] = useBulkForm(
    { typeFilter: TYPES_FILER[0], regulationFilter: REGULATION_MODES_V2[0].value }
  );
  const [detailsFields, detailsHandleChange, cleanDetailFields] = useBulkForm();
  const gbcPurposes = useGBCPurposes();

  useEffect(() => {
    if (app && app.pCode) {
      if (!themes.pending && !themes.list.length) {
        dispatch(fetchAllThemes(app.pCode));
      }
    }
  }, [app.pCode, themes]);

  useEffect(() => {
    if (app && app.pCode) {
      if (!cmpVersionV2.pending && !cmpVersionV2.list.length) {
        dispatch(fetchCMPVersionsV2(app.pCode));
      }
    }
  }, [app.pCode, cmpVersionV2]);

  useEffect(() => {
    if (app && app.pCode) {
      if (!sites.pending && !sites.list.length && sites.error.status !== 401) {
        dispatch(getSitesInitData(app.pCode));
      }
    }
  }, [app.pCode, sites]);

  useEffect(() => {
    dispatch(remove(ALERT_IDENTIFIERS.BULK_UPDATES_SUCCESS_ALERT));
    dispatch(remove(ALERT_IDENTIFIERS.BULK_UPDATES_ERROR_ALERT));
    dispatch(remove(ALERT_IDENTIFIERS.UNIVERSAL_TAG_ALERT));
  }, []);

  const filterSites = ({
    nameFilter,
    typeFilter,
    themeFilter,
    versionFilter,
    regulationFilter,
    countryFilter,
    gbcFilter,
    googleFilter,
    consentLocationsFilter,
    consentScopeFilter,
    legalBasisFilter,
    customizedFilters,
  }) => {
    const filteredResults = sites.list.filter((site) => {
      if (nameFilter && nameFilter.trim() !== '') {
        const toFilter = site.packageId || site.url;
        if (!toFilter.includes(nameFilter)) return false;
      }
      if (typeFilter) {
        switch (typeFilter) {
          case 'App':
            if (site.propertyType !== 'APP') return false;
            break;
          case 'Site':
            if (site.propertyType !== 'SITE' || site.ampEnabled) return false;
            break;
          case 'Site & Subdomains':
            if (site.propertyType !== 'SITE' || !site.applyToSubDomains || site.ampEnabled) return false;
            break;
          case 'Site AMP':
            if (site.propertyType !== 'SITE' || !site.ampEnabled) return false;
            break;
          default: return false;
        }
      }
      if (regulationFilter) {
        const privacyModes = site.privacyModes && site.privacyModes.length > 0
          ? site.privacyModes.sort().join(', ') : 'None';
        if (privacyModes !== regulationFilter) return false;
      }
      if (themeFilter && themeFilter.length > 0) {
        if (!themeFilter.includes(site.themeId)) return false;
      }
      if (versionFilter && versionFilter.length > 0) {
        const version = site.cmpVersion && site.cmpVersion.includes('latest') ? 'latest' : site.cmpVersion;
        if (!versionFilter.includes(version)) return false;
      }
      if (countryFilter && countryFilter.length > 0) {
        if (!countryFilter.includes(site.publisherCC)) return false;
      }
      if (gbcFilter) {
        if (gbcFilter === 'Yes' && !site.gbcEnabled) return false;
        if (gbcFilter === 'No' && site.gbcEnabled) return false;
      }
      if (googleFilter) {
        if (googleFilter === 'Yes' && !site.googleEnabled) return false;
        if (googleFilter === 'No' && site.googleEnabled) return false;
      }
      if (consentLocationsFilter && consentLocationsFilter.length > 0) {
        if (site.consentLocations && site.consentLocations.length > 0) {
          if (!consentLocationsFilter.some(val => site.consentLocations.includes(val))) return false;
        }
        if (site.requireConsentFrom) {
          const mappingValue = CONSENT_LOCATIONS_MAPPING[site.requireConsentFrom];
          if (!consentLocationsFilter.includes(mappingValue)) return false;
        }
      }
      if (consentScopeFilter && consentScopeFilter.length > 0) {
        if (!consentScopeFilter.includes(site.consentScope)) return false;
      }
      if (legalBasisFilter) {
        if (legalBasisFilter === 'Yes' && (site.purposeIds.length === 0 && site.purposeIds.length === 0)) {
          return false;
        }
        if (legalBasisFilter === 'No' && (site.purposeIds.length > 0 || site.purposeIds.length > 0)) {
          return false;
        }
        if (
          legalBasisFilter === 'Yes' && customizedFilters
          && (customizedFilters.purposesConsents.length > 0
            || customizedFilters.legitimateInterests.length > 0
            || customizedFilters.specialPurposes.length > 0
            || customizedFilters.features.length > 0
            || customizedFilters.specialFeatures.length > 0
          )) {
          if (customizedFilters.purposesConsents.map(
            val => site.purposeIds.includes(val)
          ).some(val => !val)) {
            return false;
          } if (customizedFilters.exactMatch) {
            if (customizedFilters.purposesConsents.length !== site.purposeIds.length) {
              return false;
            }
          }
          if (customizedFilters.legitimateInterests.map(
            val => site.purposeLegitimateIds.includes(val)
          ).some(val => !val)) {
            return false;
          } if (customizedFilters.exactMatch) {
            if (customizedFilters.legitimateInterests.length !== site.purposeLegitimateIds.length) {
              return false;
            }
          }
          if (customizedFilters.specialPurposes.map(
            val => site.specialPurposes.includes(val)
          ).some(val => !val)) {
            return false;
          } if (customizedFilters.exactMatch) {
            if (customizedFilters.specialPurposes.length !== site.specialPurposes.length) {
              return false;
            }
          }
          if (customizedFilters.features.map(
            val => site.features.includes(val)
          ).some(val => !val)) {
            return false;
          } if (customizedFilters.exactMatch) {
            if (customizedFilters.features.length !== site.features.length) {
              return false;
            }
          }
          if (customizedFilters.specialFeatures.map(
            val => site.specialFeatures.includes(val)
          ).some(val => !val)) {
            return false;
          } if (customizedFilters.exactMatch) {
            if (customizedFilters.specialFeatures.length !== site.specialFeatures.length) {
              return false;
            }
          }
        }
      }
      return true;
    }).map(item => ({
      ...item,
      propertyType: typeFilter,
      key: item.siteId
    }));
    setFilteredSites(filteredResults);
    setSelectedType(filterFields.typeFilter);
    setSelectedRegulation(filterFields.regulationFilter);
    setSelectedRowKeys([]);
    setSelectedSites([]);
    cleanDetailFields();
    setActiveKey([]);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.key]);
        setSelectedSites([...selectedSites, record]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key));
        setSelectedSites([...selectedSites.filter(item => item.siteId !== record.siteId)]);
      }
    },
    onSelectAll: (selected) => {
      if (selected) {
        setSelectedRowKeys(filteredSites.map(row => row.key));
        setSelectedSites(filteredSites);
      } else {
        setSelectedRowKeys([]);
        setSelectedSites([]);
      }
    },
    onSelectNone: () => {
      setSelectedRowKeys([]);
      setSelectedSites([]);
    }
  };


  const areAllCMPVersionSame = (selectedSites) => {
    if (selectedSites.length === 0) return true;
    const selectedVersion = selectedSites[0].cmpVersion === 'latest' ? LATEST_CMP_VERSION : selectedSites[0].cmpVersion;

    return selectedSites.every((site) => {
      const currentVersion = site.cmpVersion === 'latest' ? LATEST_CMP_VERSION : site.cmpVersion;
      return currentVersion === selectedVersion;
    });
  };

  const isGPPPropertyPresent = () => {
    if (selectedSites.length === 0) return false;
    return selectedSites.some((site) => isGPPSupported(site.cmpVersion));
  };

  const goToDetails = () => {
    history.push(`/protect/p-${app.pCode}/bulk-update/details`);
  };

  const handleNext = () => {
    if (isGPPPropertyPresent() && !areAllCMPVersionSame(selectedSites)) {
      setShowModal(true);
    } else {
      goToDetails();
    }
  };

  const handleDetailsSubmit = (data) => {
    setFieldsToUpdate(data);
    history.push(`/protect/p-${app.pCode}/bulk-update/overview`);
  };

  if (page !== undefined && !pagesAllowed.includes(page)) {
    return <NotFoundPage />;
  }

  return (
    <>
      { /* STEP 1 --------------- */ }
      {page === undefined && (
        <>
          <ContentHeader
            title={PAGE_TITLE}
            backBtn={{
              goTo: `p-${app.pCode}/properties`,
              text: BACK_TO_ALL_PROPERTIES_LABEL
            }}
            actions={[
              {
                component: Button,
                props: {
                  type: 'primary',
                  disabled: !selectedSites.length,
                  'data-testid': 'bulk_next_to_details',
                  onClick: handleNext
                },
                children: ['NEXT']
              }
            ]}
          />
          <Card id="bulk-update" className="bulk-update">
            <CardContent>
              <Alert
                message="Step 1"
                description="You can filter the properties you want to update by providing the values
                of the fields you plan to update. You can then select the properties to update."
                type="info"
                // TODO: Add instructions button when documentation URL is ready
                /* action={(
                  <Button size="small" type="link">
                    BULK UPDATES INSTRUCTIONS
                  </Button>
                )} */
                showIcon
              />
              <Spin size="large" spinning={sites.pending || themes.pending || app.loading}>
                <Collapse
                  activeKey={activeKey}
                  onChange={key => setActiveKey(key)}
                  expandIcon={({ isActive }) => (<FontAwesomeIcon icon={isActive ? faAngleDown : faAngleRight} />)}
                  ghost
                >
                  <Collapse.Panel key="1" header="Filters">
                    <BulkUpdateFilter
                      filterSites={filterSites}
                      themes={themes}
                      cmpVersionV2={cmpVersionV2}
                      fields={filterFields}
                      handleChange={filterHandleChange}
                    />
                  </Collapse.Panel>
                </Collapse>
                <Divider />
                <Table
                  columns={tableColumns(themes, cmpVersionV2)}
                  dataSource={filteredSites}
                  rowSelection={rowSelection}
                />
              </Spin>
            </CardContent>
          </Card>
          <Modal
            open={showModal}
            title="Warning - Multiple versions detected"
            footer={[
              <Button type="secondary" key="cancel" onClick={() => setShowModal(false)}>Cancel</Button>,
              <Button
                type="primary"
                key="ok"
                onClick={() => {
                  setShowModal(false);
                  goToDetails();
                }}
              >
                Next
              </Button>
            ]}
            closable={false}
            className="bulk-update-modal"
          >
            <>
              <Alert
                type="warning"
                message={`Please note that you have selected properties belonging
                to different CMP versions.`}
                showIcon
                className="alert"
              />
              <div className="body">
                <p className="warningsList">
                  In the next page,
                  <ol>
                    <li>
                      If certain fields are left blank, then default values shall be applied
                      to the properties belonging to older (53 & earlier) versions.
                    </li>
                    <li>
                      Any field selection would overwrite that particular field for all selected properties.
                    </li>
                  </ol>
                </p>
                <p className="info">
                  If you wish to proceed with current selection, then click on “Next”.
                </p>
              </div>
            </>
          </Modal>
        </>
      )}
      { /* STEP 1 --------------- */ }
      { /* STEP 2 --------------- */ }
      {page === 'details'
        && (
          <BulkUpdateForm
            propertyType={selectedType}
            regulationType={selectedRegulation}
            selectedSites={selectedRowKeys.length}
            fields={detailsFields}
            handleChange={detailsHandleChange}
            handleSubmit={handleDetailsSubmit}
            gbcPurposesData={gbcPurposes}
          />
        )
      }
      { /* STEP 2 --------------- */ }
      { /* STEP 3 --------------- */ }
      {page === 'overview' && (
        <Summary
          selectedSites={selectedSites}
          fieldsToUpdate={fieldsToUpdate}
          themes={themes}
          pCode={app.pCode}
          gbcPurposesData={gbcPurposes}
        />
      )}
      { /* STEP 3 --------------- */ }
    </>
  );
};

export default withNavbar(BulkUpdatePage);

import { Col, Row } from 'antd/es/grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'antd/es/form';
import { useHistory } from 'react-router-dom';
import {
  CHILD_PURPOSE_ID, EEA, EEA_AND_UK, GDPR, GENERAL_FORM_ERROR,
  GVL_VERSION_2, GVL_VERSION_3, SENSITIVE_PURPOSE_ID,
  XSS_ERROR_TEXT,
  USA,
  USP,
  WORLDWIDE
} from '../../../../utils/constants';
// @flow
import { createApp, editApp } from '../../../../store/duck/apps';

import Alert from '../../../../components/ui/alert';
import ConsentConfiguration from '../consent-configuration';
import ConsentScope from '../consent-scope';
import CustomizeCCPA from '../customize-ccpa';
import CustomizePurposes from '../customize-purposes';
import FormHeader from '../form-header';
import FormStyles from '../../../../components/forms/sites/sitesForm.module.scss';
import GeneralSettings from '../general-settings';
import GooglePartners from '../../../../components/ui/google-partners';
import Styles from '../../../sites/components/sites-form-page/sitesFormPage.module.scss';
import UISettings from '../ui-settings';
import { fetchCMPVersionsV2 } from '../../../../store/duck/versionsV2';
import { formatCustomLinks } from '../../../../utils/themes';
import { getGBCInitData } from '../../../../components/forms/gbc/utils';
import { getTranslationsForLang } from '../../../../utils/language';
import { setImageSizeParameters } from '../../../../utils/setImageSize';
import validations from '../../validations';
import useGBCPurposes from '../../../../components/forms/gbc/useGBCPurposes';
import Loader from '../../../../components/ui/loader';
import MSPARegulationSettings from '../../../../components/forms/mspa_settings';
import ConsentScopeUSP from '../../../../components/forms/consent_scope_usp';
import { getConsentLocation, getMSPAInitData } from '../../../../utils/property';
import { validateUserInput } from '../../../../utils/sites';

type Props = {
  current: Object;
  titlePage: string;
  isNew: Boolean;
};


function AppForm({ current, titlePage, isNew }: Props) {
  const gbcPurposesData = useGBCPurposes();
  const isCurrentObjectEmpty = !isNew && Object.keys(current).length === 0;
  const formInitDataLoading = gbcPurposesData.length === 0 || isCurrentObjectEmpty;

  const getCustomLinks = (string) => {
    let parsed = [];
    try {
      parsed = JSON.parse(string);
    } catch (e) {
      parsed = formatCustomLinks(string);
    }
    return parsed;
  };

  const initDataRegulations = () => {
    let regulations = [];
    const { consentLocations, privacyModes, requireConsentFrom } = current;

    const consentLocation = getConsentLocation(consentLocations, privacyModes, requireConsentFrom);
    if (consentLocation.includes(WORLDWIDE) || (consentLocation.includes(EEA)
      && consentLocation.includes(USA))) {
      regulations.push(...[GDPR, USP]);
    } else if (consentLocation.includes(EEA)) {
      regulations.push(GDPR);
    } else if (consentLocation.includes(USA)) {
      regulations.push(USP);
    } else {
      regulations.push(GDPR);
    }
    return regulations;
  };

  const INIT_DATA = useMemo(() => ({
    packageId: current.packageId || '',
    publisherName: current.publisherName || '',
    publisherLogoUrl: current.publisherLogoUrl || '',
    hasGDPR: initDataRegulations().includes('GDPR'),
    hasCCPA: initDataRegulations().includes('USP'),
    cmpVersion: current.cmpVersion || 'latest',
    publisherCC: current.publisherCC || 'GB',
    uspLspact: current.uspLspact === 'Y',
    uspJurisdiction: current.uspJurisdiction ? current.uspJurisdiction.includes('US') : false,
    initScreenRejectButtonShowing: current.initScreenRejectButtonShowing || false,
    initScreenRejectButton: current.initScreenRejectButton || '',
    uxInitialScreenCustomLinks: current.uxInitialScreenCustomLinks && current.uxInitialScreenCustomLinks.length
      ? getCustomLinks(current.uxInitialScreenCustomLinks)
      : [],
    requireConsentFrom: current.requireConsentFrom || 'always',
    consentScope: current.consentScope || 'service',
    consentScopeGroupURL: current.consentScopeGroupURL || '',
    thirdPartyStorageType: current.thirdPartyStorageType || 'iframe',
    redirectUrl: current.redirectUrl || '',
    repromtDaysAfterGvlUpdate: current.repromtDaysAfterGvlUpdate || 30,
    isDefaultConsent: current.isDefaultConsent || true,
    noLegalBasis: current.noLegalBasis || true,
    purposeIdsV2: current.purposeIds || [],
    purposeLegitimateIdsV2: current.purposeLegitimateIds || [],
    specialFeatures: current.specialFeatures || [],
    stackIds: current.stackIds || [],
    specialPurposes: current.specialPurposes || [],
    features: current.features || [],
    propertyType: current.propertyType || 'APP',
    googleEnabled: current.googleEnabled || false,
    language: current.language || 'en',
    use: current.vendorConfig
        && current.vendorConfig.stacks
        && current.vendorConfig.stacks.length > 0 ? 'stacks' : 'purposes',
    userSelectedStack: current.vendorConfig
        && current.vendorConfig.stacks
        && current.vendorConfig.stacks.length > 0,
    stacks: current.vendorConfig && current.vendorConfig.stacks ? current.vendorConfig.stacks : [],
    vendorConfig: {
      vendorPurposeIds: [],
      vendorFeaturesIds: [],
      vendorPurposeLegitimateInterestIds: [],
      vendorSpecialFeaturesIds: [],
      vendorSpecialPurposesIds: [],
      publisherConsentRestrictionIds: [],
      publisherLIRestrictionIds: [],
      stacks: [],
      ...current.vendorConfig
    },
    uspDnsTitleText: current.uspDnsTitleText || '',
    uspDnsText: current.uspDnsText || '',
    suppressCcpaLinks: current.suppressCcpaLinks || false,
    uspDeleteDataLinkText: current.uspDeleteDataLinkText || '',
    uspDeleteDataLink: current.uspDeleteDataLink || '',
    uspAccessDataLinkText: current.uspAccessDataLinkText || '',
    uspAccessDataLink: current.uspAccessDataLink || '',
    uspPrivacyPolicyLinkText: current.uspPrivacyPolicyLinkText || '',
    uspPrivacyPolicyLink: current.uspPrivacyPolicyLink || '',
    gvlVersion: current.gvlVersion || GVL_VERSION_3,
    ...getGBCInitData(current, gbcPurposesData),
    ...getMSPAInitData(current),
  }), [current, gbcPurposesData]);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const { apps, cmpVersionV2, app: { pCode }, sites } = useSelector(state => state);
  const [values, setValues] = useState(() => {
    const extras = {};
    extras.purposeIdsV2 = INIT_DATA.purposeIdsV2.length ? INIT_DATA.purposeIds : [];

    if (INIT_DATA.purposeLegitimateIdsV2.length) {
      extras.legitimatePurposes = INIT_DATA.purposeLegitimateIdsV2;
    }

    return { ...INIT_DATA, ...extras };
  });
  const [hasChangedValues, setHasChangedValues] = useState(false);
  const [vendorConfig, setVendorConfig] = useState({});
  const [generalError, setGeneralFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(GENERAL_FORM_ERROR);
  const { setFieldsValue, validateFields, getFieldValue } = form;
  const validateForm = validations();

  const onValuesChange = useCallback(() => {
    if (!hasChangedValues) {
      setHasChangedValues(true);
    }
  }, [hasChangedValues]);

  useEffect(() => {
    if (!cmpVersionV2.list.length && !cmpVersionV2.pending) {
      dispatch(fetchCMPVersionsV2(pCode));
    }

    if (cmpVersionV2.list.length) {
      setValues({
        ...values,
        cmpVersion: cmpVersionV2.list[0]
      });
    }
  }, [cmpVersionV2]);

  useEffect(() => {
    setValues({ ...INIT_DATA });
  }, [current, gbcPurposesData]);

  const appDataTest = input => `app-${input}-input`;

  const handleVendorCustomization = (customization) => {
    setVendorConfig(customization);
  };

  const handleSubmitCustomLinks = (valuesToSend) => {
    const links = [];
    const {
      uxInitialScreenCustomLinksText0,
      uxInitialScreenCustomLinksUrl0,
      uxInitialScreenCustomLinksText1,
      uxInitialScreenCustomLinksUrl1,
      uxInitialScreenCustomLinksText2,
      uxInitialScreenCustomLinksUrl2
    } = valuesToSend;

    if (uxInitialScreenCustomLinksText0 && uxInitialScreenCustomLinksUrl0) {
      links.push({
        text: uxInitialScreenCustomLinksText0,
        url: uxInitialScreenCustomLinksUrl0
      });
    }
    if (uxInitialScreenCustomLinksText1 && uxInitialScreenCustomLinksUrl1) {
      links.push({
        text: uxInitialScreenCustomLinksText1,
        url: uxInitialScreenCustomLinksUrl1
      });
    }
    if (uxInitialScreenCustomLinksText2 && uxInitialScreenCustomLinksUrl2) {
      links.push({
        text: uxInitialScreenCustomLinksText2,
        url: uxInitialScreenCustomLinksUrl2
      });
    }
    return links;
  };

  const sendInfoForm = (valuesToSend) => {
    if (Object.keys(current).length) {
      dispatch(editApp(current.siteId, valuesToSend));
    } else {
      dispatch(createApp(valuesToSend));
    }
  };

  const handleSubmit = () => {
    validateFields().then((formValues) => {
      const valuesToSend = {
        ...values,
        ...formValues,
        pCode,
        majorVersion: '2',
        purposeIds: values.purposeIdsV2 ? values.purposeIdsV2 : [],
        specialFeatures: values.specialFeatures,
        specialPurposes: values.specialPurposes,
        features: values.features,
        purposeLegitimateIds: values.purposeLegitimateIdsV2 ? values.purposeLegitimateIdsV2 : [],
        repromtDaysAfterGvlUpdate: Number(formValues.repromtDaysAfterGvlUpdate),
        gbcApplicablePurposes: values.gbcApplicablePurposes
      };
      if (valuesToSend.gbcEnabled) {
        valuesToSend.gbcLocations = [valuesToSend.gbcLocations];

        const gbcPurposes = [];
        valuesToSend.gbcApplicablePurposes.forEach((purpose) => {
          if (purpose.enabled) {
            gbcPurposes.push({ id: purpose.id, defaultValue: purpose.defaultValue });
          }
        });
        valuesToSend.gbcApplicablePurposes = gbcPurposes;
      } else {
        delete valuesToSend.gbcLocations;
        delete valuesToSend.gbcApplicablePurposes;
      }

      if (values.suppressCcpaLinks) {
        valuesToSend.uspDeleteDataLinkText = '';
        valuesToSend.uspDeleteDataLink = '';
        valuesToSend.uspAccessDataLinkText = '';
        valuesToSend.uspAccessDataLink = '';
        valuesToSend.uspPrivacyPolicyLinkText = '';
        valuesToSend.uspPrivacyPolicyLink = '';
      }

      valuesToSend.uspJurisdiction = valuesToSend.uspJurisdiction ? ['US'] : ['CA'];
      valuesToSend.uspLspact = valuesToSend.uspLspact ? 'Y' : 'N';
      valuesToSend.vendorConfig = vendorConfig;

      if (!valuesToSend.vendorConfig.stacks) {
        valuesToSend.vendorConfig.stacks = valuesToSend.stacks || [];
      }

      const {
        publisherLIRestrictionIds = [],
        publisherConsentRestrictionIds = []
      } = valuesToSend.restrictions ? valuesToSend.restrictions : {
        publisherLIRestrictionIds: [],
        publisherConsentRestrictionIds: []
      };

      valuesToSend.vendorConfig.publisherLIRestrictionIds = publisherLIRestrictionIds;
      valuesToSend.vendorConfig.publisherConsentRestrictionIds = publisherConsentRestrictionIds;

      if (values.use === 'stacks') {
        valuesToSend.vendorConfig.stacks = values.stacks;
      } else {
        valuesToSend.vendorConfig.stacks = [];
      }
      const links = handleSubmitCustomLinks(valuesToSend);
      if (links && links.length) {
        valuesToSend.uxInitialScreenCustomLinks = links.map(link => (
          `[${link.text}](${link.url})`
        )).join();
      } else {
        delete valuesToSend.uxInitialScreenCustomLinks;
      }

      if (values.noLegalBasis) {
        valuesToSend.purposeIds = [];
        valuesToSend.purposeLegitimateIds = [];
        valuesToSend.features = [];
        valuesToSend.specialFeatures = [];
        valuesToSend.specialPurposes = [];
      }

      if (valuesToSend.hasCCPA) {
        valuesToSend.mspaOptOutPurposeIds = [
          ...(formValues.mspaPurposeIds || []),
          ...(formValues.mspaSensitiveDataProcessing ? [SENSITIVE_PURPOSE_ID] : []),
          ...(formValues.mspaChildSensitiveDataProcessing ? [CHILD_PURPOSE_ID] : [])
        ];
        valuesToSend.mspaSignalMode = 'OPT_OUT';
        if (!valuesToSend.mspaSensitiveDataProcessing) delete valuesToSend.mspaSensitiveDataPurposeIds;
      }
      delete valuesToSend.mspaPurposeIds;
      delete valuesToSend.mspaSensitiveDataProcessing;
      delete valuesToSend.mspaChildSensitiveDataProcessing;

      valuesToSend.purposeIds.forEach(p => delete valuesToSend[`legitime.${p}`]);
      delete valuesToSend.hasGDPR;
      delete valuesToSend.hasCCPA;
      delete valuesToSend.publisherConsentRestrictionIds;
      delete valuesToSend.publisherLIRestrictionIds;
      delete valuesToSend.stacks;
      delete valuesToSend.use;
      delete valuesToSend.uxInitialScreenCustomLinksText0;
      delete valuesToSend.uxInitialScreenCustomLinksUrl0;
      delete valuesToSend.uxInitialScreenCustomLinksText1;
      delete valuesToSend.uxInitialScreenCustomLinksUrl1;
      delete valuesToSend.uxInitialScreenCustomLinksText2;
      delete valuesToSend.uxInitialScreenCustomLinksUrl2;
      delete valuesToSend.purposeIdsV2;
      delete valuesToSend.purposeLegitimateIdsV2;
      delete valuesToSend.radioGroup;
      delete valuesToSend.logoUrlSize;

      // Validate the html input for XSS
      const areValidInput = validateUserInput({ uspDnsText: valuesToSend.uspDnsText });
      if (!areValidInput) {
        throw new Error('XSS_ERROR');
      }

      if (formValues.publisherLogoUrl) {
        setImageSizeParameters(formValues.publisherLogoUrl, true).then((logoUrlSize) => {
          valuesToSend.publisherLogoUrl = `${formValues.publisherLogoUrl}${logoUrlSize}`;
          sendInfoForm(valuesToSend);
        }).catch((error) => {
          setGeneralFormError(true);
          setErrorMessage(error);
        });
      } else {
        sendInfoForm(valuesToSend);
      }
    }).catch((err) => {
      setGeneralFormError(true);
      if (err.message === 'XSS_ERROR') {
        setErrorMessage(XSS_ERROR_TEXT);
      }
    });
  };

  const handleUserSelectedStack = (value) => {
    setValues({ ...values, userSelectedStack: value });
  };

  const changeRegulations = (regulations) => {
    setValues(prevValues => ({
      ...prevValues,
      hasGDPR: regulations.includes('GDPR'),
      hasCCPA: regulations.includes('USP')
    }));
  };

  const onChangePublisherCC = (value) => {
    // if the publisherCC is set to an EEA or UK force requireConsentFrom to 'always'
    if (EEA_AND_UK.includes(value)) {
      setFieldsValue({ requireConsentFrom: 'always' });
    }
  };

  const initDataConsentConfiguration = () => ((INIT_DATA.purposeIdsV2 && INIT_DATA.purposeIdsV2.length)
    ? 'customized' : 'applyRecommended');

  const initialScreenCustomLinks = () => INIT_DATA.uxInitialScreenCustomLinks.reduce(
    (acc, ux, index) => (
      {
        ...acc,
        [`uxInitialScreenCustomLinksText${index}`]: ux.text,
        [`uxInitialScreenCustomLinksUrl${index}`]: ux.url
      }
    ), {}
  );

  const vendorsData = useSelector(
    state => (values.gvlVersion === GVL_VERSION_2 ? state.vendors.v2 : state.vendors.v3)
  );

  // Enable the Save Button if the app is on an old version, So that the user can save the default values of newly added fields.
  const isAppOnOldVersion = !current.consentLocations;

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.header}>
        <FormHeader
          pending={apps.pending}
          handleSubmit={handleSubmit}
          titlePage={titlePage}
          hasChanges={hasChangedValues || isAppOnOldVersion}
        />
      </div>
      {formInitDataLoading ? <Loader /> : (
        <div className={Styles.container}>
          <section className={Styles.content}>
            {generalError && (
              <Alert type="error" message={errorMessage} />
            )}
            <Form
              name="app-form"
              form={form}
              className={FormStyles.cardsWrapper}
              layout="vertical"
              initialValues={{
                ...INIT_DATA,
                ...initialScreenCustomLinks(),
                privacyModes: initDataRegulations(),
                purposeLegitimateIdsV2: [],
                purposeIdsV2: [],
                specialPurposes: [],
                specialFeatures: [],
                radioGroup: initDataConsentConfiguration(),
              }}
              onValuesChange={onValuesChange}
            >
              <Row gutter={[16, 16]}>
                {apps.error.status !== 200 && (
                  <Col span="24">
                    <Alert
                      message="There was an error creating your app"
                      type="error"
                      description={apps.error.message || 'There was an unexpected error, please try again later'}
                    />
                  </Col>
                )}
                <Col span="24">
                  <GeneralSettings
                    dataTest={appDataTest}
                    hasGDPR={values.hasGDPR}
                    hasCCPA={values.hasCCPA}
                    hasLegalBasis={!values.noLegalBasis}
                    changeRegulations={changeRegulations}
                    onChangePublisherCC={onChangePublisherCC}
                    setValues={setValues}
                    validations={validateForm}
                    sites={sites.list}
                    isNew={isNew}
                    validateFields={validateFields}
                    setFieldsValue={setFieldsValue}
                    getFieldValue={getFieldValue}
                    values={values}
                  />
                </Col>

                {values.hasGDPR && (
                  <Col span="24">
                    <ConsentScope
                      setDataTestId={appDataTest}
                      consentScopeIsGroup={values.consentScope === 'service group'}
                      getFieldValue={getFieldValue}
                    />
                  </Col>
                )}

                {values.hasGDPR && (
                  <Col span="24">
                    <ConsentConfiguration
                      values={values}
                      initData={INIT_DATA}
                      purposes={values.purposeIdsV2}
                      legitimatePurposes={values.purposeLegitimateIdsV2}
                      setFieldsValue={setFieldsValue}
                      validateFields={validateFields}
                      validatePurposes={[]}
                      setValues={setValues}
                      getFieldValue={getFieldValue}
                      setDataTestId={appDataTest}
                      vendorsData={vendorsData}
                    />
                  </Col>
                )}

                {values.hasGDPR && (
                  <Col span="24">
                    <CustomizePurposes
                      onChange={(newValues, isUserChangedValues = false) => {
                        setValues(prevValues => ({ ...prevValues, ...newValues }));
                        if (isUserChangedValues) onValuesChange();
                      }}
                      values={values}
                      initData={INIT_DATA}
                      appDataTest={appDataTest}
                      use={values.use}
                      userSelectedStack={values.userSelectedStack}
                      stackIds={values.stacks}
                      setUserSelectedStack={handleUserSelectedStack}
                      pCode={pCode}
                      handleCustomization={handleVendorCustomization}
                      vendorsData={vendorsData.data}
                    />
                  </Col>
                )}

                {values.hasGDPR && (
                  <Col span="24">
                    <GooglePartners appDataTest={appDataTest} pcode={pCode} />
                  </Col>
                )}
                <Col span="24">
                  <UISettings
                    appDataTest={appDataTest}
                    onChange={setValues}
                    disagreeButtonTextDisabled={!values.initScreenRejectButtonShowing}
                    validations={validateForm}
                    validateFields={validateFields}
                    customLinks={INIT_DATA.uxInitialScreenCustomLinks}
                    translations={getTranslationsForLang(values.language)}
                    setDataTestId={input => `app-customlink-${input}-input`}
                    regulationEnabled={values.hasGDPR || values.hasCCPA}
                  />
                </Col>

                {values.hasCCPA && (
                  <>
                    <Col span="24">
                      <MSPARegulationSettings
                        className={Styles.addTopMargin}
                        setFieldsValue={form.setFieldsValue}
                        getFieldValue={form.getFieldValue}
                      />
                    </Col>
                    <Col span="24">
                      <ConsentScopeUSP
                        className={Styles.addTopMargin}
                        setFieldsValue={form.setFieldsValue}
                        getFieldValue={form.getFieldValue}
                        validateFields={form.validateFields}
                      />
                    </Col>
                    <Col span="24">
                      <CustomizeCCPA
                        values={values}
                        handleChange={setValues}
                        validations={validateForm}
                        isApp={true}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </section>
          <aside className={Styles.aside} />
        </div>
      )}
    </section>
  );
}

export default AppForm;

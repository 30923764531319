import { buildError } from '../../utils/reducer';
import { THEMES } from '../actions';

const defState = {
  current: {},
  list: [],
  error: {
    status: 200,
    message: ''
  },
  pending: false,
  formError: {
    status: undefined,
    message: ''
  },
  formPending: false,
  useFilterData: false,
  deleteTheme: { pending: false }
};

export function fetchAllThemes(pcode, isPremium = true) {
  return {
    type: THEMES.GET_ALL.FETCH,
    payload: { pcode, isPremium }
  };
}

export function useFilterData() {
  return { type: THEMES.GET_ALL.USEFILTERDATA };
}

export function createTheme(themeData, fromSite) {
  return {
    type: THEMES.CREATE.FETCH,
    payload: { themeData, fromSite }
  };
}

export function editTheme(themeId, themeData) {
  return {
    type: THEMES.EDIT.FETCH,
    payload: { themeId, themeData }
  };
}

export function getById(id, pCode) {
  return {
    type: THEMES.GET_BY_ID.FETCH,
    payload: { id, pCode }
  };
}

export function deleteTheme({ idTheme, pCode, nameTheme }) {
  return {
    type: THEMES.DELETE.FETCH,
    payload: { idTheme, pCode, nameTheme }
  };
}

export function clearCurrent() {
  return { type: THEMES.GET_BY_ID.CLEANED };
}

function themesReducer(state = defState, action) {
  switch (action.type) {
    // Pending
    case THEMES.GET_ALL.PENDING:
    case THEMES.GET_BY_ID.PENDING: {
      return { ...state, pending: true, current: {}, error: { status: 200, message: '' }, formPending: false };
    }
    case THEMES.CREATE.PENDING: {
      return { ...state, pending: true, current: {}, error: { status: 200, message: '' }, formPending: true };
    }
    case THEMES.EDIT.PENDING: {
      return { ...state, pending: true, error: { status: 200, message: '' }, formPending: true };
    }
    // Fulfilled
    case THEMES.GET_BY_ID.FULFILLED: {
      return { ...state, current: action.payload, pending: false, useFilterData: false, formPending: false };
    }
    case THEMES.CREATE.FULFILLED: {
      return {
        ...state,
        list: [action.payload, ...state.list],
        pending: false,
        useFilterData: false,
        formPending: false,
        formError: { status: 200, message: '' }
      };
    }
    case THEMES.EDIT.FULFILLED: {
      const list = [...state.list].filter(item => item.themeId !== action.payload.id);
      list.push(action.payload.data);

      return {
        ...state,
        pending: false,
        list,
        useFilterData: false,
        formPending: false,
        formError: { status: 200, message: '' }
      };
    }
    case THEMES.GET_ALL.FULFILLED: {
      return {
        ...state,
        list: action.payload,
        pending: false,
        error: buildError({ status: 200, message: '' }),
        useFilterData: false
      };
    }
    // Rejected
    case THEMES.GET_ALL.REJECTED:
    case THEMES.CREATE.REJECTED:
    case THEMES.EDIT.REJECTED: {
      return {
        ...state,
        pending: false,
        error: buildError(action.payload),
        formPending: false,
        formError: {
          status: action.payload.status,
          message: action.payload.message
        }
      };
    }
    case THEMES.GET_BY_ID.REJECTED: {
      return { ...state, current: {}, pending: false, error: buildError(action.payload) };
    }
    // Others
    case THEMES.GET_BY_ID.CLEANED: {
      return {
        ...state,
        current: {},
        formPending: false,
        formError: {
          status: undefined,
          message: ''
        }
      };
    }
    case THEMES.GET_ALL.USEFILTERDATA: {
      return { ...state, useFilterData: true };
    }
    case THEMES.DELETE.PENDING: {
      return {
        ...state,
        deleteTheme: { pending: true }
      };
    }

    case THEMES.DELETE.FULFILLED: {
      return {
        ...state,
        deleteTheme: { pending: false }
      };
    }
    case THEMES.DELETE.REJECTED: {
      return {
        ...state,
        deleteTheme: { pending: false }
      };
    }
    default: {
      return state;
    }
  }
}

export default themesReducer;

// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { NotFoundPage } from '../not-found';
import withNavbar from '../../components/hoc/with-navbar';
import AppForm from './components/form';
import { getById, cleanApp } from '../../store/duck/apps';
import Loader from '../../components/ui/loader';
import Details from './components/details';
import { getSitesInitData } from '../../store/duck/sites';

type Props = {
  action: String;
  apps: Object;
  loading: Boolean;
  app: {
    pCode: string;
  },
  titlePage: string;
}

const Render = React.memo(({ action, apps, loading, app, titlePage }: Props) => {
  const isEdit = action === 'edit';
  const isCurrentSet = Object.keys(apps.current).length > 0;
  // wait for the current object to be defined if it is editing to avoid weird states in the form
  if (loading || apps.pending || !app.pCode || (isEdit && !isCurrentSet)) {
    return <Loader />;
  }
  if (!loading && action === undefined) return <Redirect to={`/protect/${app.pCode}/sites`} />;
  if (!loading && ['new', 'edit'].some(_action => action === _action)) {
    return <AppForm titlePage={titlePage} current={action === 'new' ? {} : apps.current} isNew={!isEdit} />;
  }
  if (!loading && action === 'detail') return <Details pCode={app.pCode} />;
  return <NotFoundPage />;
});

function Apps() {
  const { app, apps } = useSelector(state => state);
  const { action, id } = useParams();
  const titlePage = action === 'new' ? 'Create New App' : 'Edit an App';
  const history = useHistory();
  const dispatch = useDispatch();
  let appsTimeout;

  useEffect(() => {
    if (
      id
      && !apps.pending
      && apps.error.status !== 401
      && apps.error.status !== 404
      && !apps.fetched
      && app.pCode
      && Object.keys(apps.current).length === 0
    ) {
      dispatch(getById(id, app.pCode));
    }
    dispatch(getSitesInitData(app.pCode));
  }, [apps.fetched, app.pCode]);

  useEffect(() => {
    if (apps.error.status !== 200) {
      appsTimeout = setTimeout(() => {
        dispatch(cleanApp());
      }, 5000);
    } else if (
      apps.fetched
      && apps.error.status === 200
      && Object.keys(apps.current).length === 0) {
      dispatch(cleanApp());
      history.push(`/protect/p-${app.pCode}/properties`);
    }
    return () => clearTimeout(appsTimeout);
  }, [apps]);

  useEffect(() => {
    dispatch(cleanApp());
  }, []);

  if (!app.loading) {
    if (action === 'edit' && !id) {
      history.push(`/protect/p-${app.pCode}/properties`);
    }
  }
  return <Render action={action} apps={apps} loading={app.loading} app={app} titlePage={titlePage} />;
}

export default withNavbar(Apps);

// @flow
import React, { useState, useEffect } from 'react';
import Loader from '../../components/ui/loader';
import type { iThemesFormContainerProps } from '../../interfaces/themes';
import Styles from './themesForm.module.scss';
import Unauthorized from '../../components/ui/unauthorized';
import ThemesForm from '../../components/forms/themes';
import NotFound from '../not-found';
import { GVL_VERSION_3, GVL_VERSION_2 } from '../../utils/constants';

const ThemesFormContainer = ({
  actions,
  stacks,
  app,
  history,
  location,
  match,
  themes,
  vendors,
}: iThemesFormContainerProps) => {
  const tcfVersion = (themes.current && themes.current.gvlVersion) || GVL_VERSION_3;
  const [vendorsData, setVendorsData] = useState(tcfVersion === GVL_VERSION_2 ? vendors.v2 : vendors.v3);
  const [viewType, setViewType] = useState('Create');
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const { current, pending, error, formError, formPending } = themes;
    if (app.pCode) {
      actions.alerts.clear();
      if (match.params.action === 'edit') {
        setViewType('Edit');
        if (!(Object.keys(current).length) && !(pending) && error.status !== 401 && error.status !== 404) {
          actions.themes.getById(match.params.id, app.pCode);
        }
        if ((Object.keys(current).length && !(pending)) || error.status === 401) {
          setLoader(false);
        }
      } else if (match.params.action === 'new') {
        setViewType('Create');
        if (Object.keys(current).length) {
          actions.themes.clearCurrent();
        }
        if (app.pCode && !(Object.keys(current).length)) {
          setLoader(false);
        }
      }

      if (!Object.keys(vendors.v2.data).length && !vendors.v2.pending) {
        actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_2);
      }
      if (!Object.keys(vendors.v3.data).length && !vendors.v3.pending) {
        actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_3);
      }
    }

    if (!formPending && formError.status && formError.status === 200) {
      const params = new URLSearchParams(location.search);
      if (params.get('from-site')) {
        history.push(`/protect/p-${app.pCode}/sites`);
      } else {
        history.push(`/protect/p-${app.pCode}/themes`);
      }
    }
  }, [app, themes]);

  useEffect(() => {
    setVendorsData(tcfVersion === GVL_VERSION_2 ? vendors.v2 : vendors.v3);
  }, [tcfVersion, vendors]);

  const renderContainer = () => {
    if ([undefined, 'new', 'edit'].indexOf(match.params.action) === -1
      || (match.params.action === 'edit' && !(match.params.id))
      || themes.error.status === 404
    ) {
      return <NotFound />;
    }

    if (loader || !Object.keys(vendorsData.data).length) {
      return <Loader />;
    }

    if (themes.error.status === 401 || themes.current.themeId === 1) {
      return (<Unauthorized />);
    }

    return (
      <ThemesForm
        mode={viewType}
        createTheme={actions.themes.createTheme}
        editTheme={actions.themes.editTheme}
        deleteTheme={actions.themes.deleteTheme}
        stacks={stacks}
        themes={themes}
        app={app}
        idParams={match.params.id}
        location={location}
        gvlData={vendorsData.data}
        updateTCFVersion={(version) => {
          setVendorsData(version === GVL_VERSION_2 ? vendors.v2 : vendors.v3);
        }}
      />
    );
  };

  return (
    <section className={Styles.wrapper}>
      {renderContainer()}
    </section>
  );
};

export default ThemesFormContainer;

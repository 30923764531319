// @flow
import { Checkbox, Form, Select, Tooltip } from 'antd/es';
import { Col, Row } from 'antd/es/grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uid from 'uid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-pro-light';
import { COUNTRY_CODES, LATEST_CMP_VERSION, REGULATION_MODES } from '../../../../utils/constants';
import Card from '../../../../components/ui/card';
import InfoIconTooltip from '../../../../components/ui/infoIconTooltip';
import Spin from '../../../../components/ui/spin';
import { fetchAllThemes } from '../../../../store/duck/themes';
import { fetchCMPVersionsV2 } from '../../../../store/duck/versionsV2';
import GBCForm from '../../../../components/forms/gbc';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import { isGBCSupported, isGPPSupported } from '../../../../utils/property';

type Props = {
  propertyType: string;
  fields: Record<any>;
  handleChange: Function;
  gbcPurposesData: Object;
  filteredRegulation: string;
  form: any;
};

const GeneralSettings = ({ propertyType, fields, handleChange, gbcPurposesData, form, filteredRegulation }: Props) => {
  const { CardHeader, CardContent } = Card;
  const { Option } = Select;

  const { setFieldsValue, validateFields, getFieldValue } = form;
  const { pCode } = useSelector(state => state.app);
  const themes = useSelector(state => state.themes);
  const versions = useSelector(state => state.cmpVersionV2);

  const dispatch = useDispatch();
  const [consentLocations, setConsentLocations] = useState(getFieldValue('consentLocations') || ['WORLDWIDE']);

  const isDisabled = values => values.some(value => consentLocations.includes(value));

  const consentLocationsOptions = [
    {
      value: 'EEA',
      label: 'EEA & UK',
      disabled: isDisabled(['WORLDWIDE', 'NEVER'])
    },
    {
      value: 'WORLDWIDE',
      label: 'Worldwide',
      disabled: isDisabled(['EEA', 'USA', 'NEVER'])
    },
    {
      value: 'USA',
      label: 'USA',
      disabled: isDisabled(['WORLDWIDE', 'NEVER'])
    },
    {
      value: 'NEVER',
      label: 'Never',
      disabled: isDisabled(['WORLDWIDE', 'EEA', 'USA'])
    }
  ];

  const consentLocationChanged = (value) => {
    setConsentLocations(value);
    const isWorldwideSelected = value.includes('WORLDWIDE');
    const privacyModes = {
      hasGDPR: false,
      hasCCPA: false
    };
    if (isWorldwideSelected) {
      privacyModes.hasGDPR = true;
      privacyModes.hasCCPA = true;
    } else {
      if (value.includes('EEA') || value.includes('NEVER')) privacyModes.hasGDPR = true;
      if (value.includes('USA')) privacyModes.hasCCPA = true;
    }
    handleChange(['hasGDPR', 'hasCCPA', 'consentLocations'], { ...privacyModes, consentLocations: value });
  };

  const handleVersionChange = (value) => {
    if (isGPPSupported(value)) {
      handleChange(['cmpVersion', 'consentLocations', 'hasGDPR', 'hasCCPA'], {
        cmpVersion: value,
        consentLocations: ['WORLDWIDE'],
        hasGDPR: true,
        hasCCPA: true
      });
    } else {
      handleChange(['cmpVersion', 'hasGDPR', 'hasCCPA'], {
        cmpVersion: value,
        hasGDPR: filteredRegulation === REGULATION_MODES[0].value || filteredRegulation === REGULATION_MODES[2].value,
        hasCCPA: filteredRegulation === REGULATION_MODES[1].value || filteredRegulation === REGULATION_MODES[2].value
      });
    }
  };

  useEffect(() => {
    if (!versions.pending && versions.list.length === 0) {
      dispatch(fetchCMPVersionsV2(pCode));
    }
  }, [versions]);

  useEffect(() => {
    if (propertyType !== 'App' && themes.list.length === 0) {
      dispatch(fetchAllThemes(pCode));
    }
  }, [themes]);

  const handlePrivacyModesChanges = (mode, event) => {
    const { checked } = event.target;
    const privacyModes = {
      hasGDPR: fields.hasGDPR,
      hasCCPA: fields.hasCCPA,
      ccpaUS: fields.ccpaUS,
      uspLspact: fields.uspLspact,
    };
    if ((['ccpaUS', 'uspLspact'].includes(mode) && checked) && fields.hasCCPA === undefined) {
      privacyModes.hasCCPA = true;
    }
    privacyModes[mode] = checked;

    handleChange(['hasGDPR', 'hasCCPA', 'ccpaUS', 'uspLspact'], privacyModes);
  };

  return (
    <Card>
      <CardHeader title="General Settings" />
      <CardContent>
        <Row gutter={[16, 16]}>
          {propertyType !== 'App' && (
            <Col span="24">
              <Spin size="large" spinning={themes.pending}>
                <Form.Item label="CHOOSE A THEME">
                  <Select
                    allowClear
                    id="themeId"
                    value={fields.themeId}
                    onChange={value => handleChange('themeId', value)}
                  >
                    {themes.list.map(
                      theme => <Option key={theme.themeId} value={theme.themeId}>{theme.themeName}</Option>
                    )}
                  </Select>
                </Form.Item>
              </Spin>
            </Col>
          )}
          {propertyType !== 'App' && (
            <Col span="24">
              <Spin size="large" spinning={versions.pending}>
                <Form.Item label="CMP VERSION">
                  <Select
                    allowClear
                    id="cmpVersion"
                    value={fields.cmpVersion}
                    onChange={handleVersionChange}
                  >
                    {versions.list.map(
                      version => (
                        <Option
                          key={version}
                          value={version.includes('latest') ? 'latest' : version}
                        >
                          {version}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Spin>
            </Col>
          )}
          <Col span="24">
            <Form.Item
              label="PUBLISHER COUNTRY CODE"
              tooltip={{
                title: (
                  <>
                    The country code of the country that determines legislation of reference.
                    Commonly, this corresponds to the country in which the publisher’s business
                    entity is established. For more information, please visit &nbsp;
                    <a href="https://iabeurope.eu/tcf-2-0/" target="_blank" rel="noopener noreferrer">
                      IAB TCF v2.0
                    </a>
                  </>
                ),
                icon: <span><InfoIconTooltip /></span>,
              }}
            >
              <Select
                allowClear
                id="publisherCC"
                value={fields.publisherCC}
                onChange={value => handleChange('publisherCC', value)}
              >
                {COUNTRY_CODES.map(
                  country => <Option key={country.label} value={country.value}>{country.label}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          {
            isGPPSupported(fields.cmpVersion) && (
              <Col span="24">
                <Form.Item
                  label={[<React.Fragment key={uid}>{'WHICH USERS SHOULD BE ASKED FOR CONSENT'}</React.Fragment>]}
                  tooltip={{
                    title: SITETOOLTIPS.consentLocations,
                    icon: (
                      <span>
                        <InfoIconTooltip />
                      </span>
                    )
                  }}
                >
                  <Select
                    value={fields.consentLocations}
                    mode="multiple"
                    allowClear
                    onChange={consentLocationChanged}
                    options={consentLocationsOptions}

                  />
                </Form.Item>
              </Col>
            )
          }
          {
            !isGPPSupported(fields.cmpVersion) && (
              <>
                <Col span={(fields.hasCCPA || fields.hasCCPA === undefined) ? '12' : '24'}>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        label="REGULATION"
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <Checkbox
                          indeterminate={fields.hasGDPR === undefined}
                          id="regulationGDPR"
                          onChange={event => handlePrivacyModesChanges('hasGDPR', event)}
                          checked={fields.hasGDPR}
                        >
                          European regulation (GDPR)
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span="24">
                      <Form.Item>
                        <Checkbox
                          indeterminate={fields.hasCCPA === undefined}
                          id="regulationCCPA"
                          onChange={event => handlePrivacyModesChanges('hasCCPA', event)}
                          checked={fields.hasCCPA}
                        >
                          US regulation
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {(fields.hasCCPA) && (
                  <Col span="12">
                    <Row>
                      <Col span="24">
                        <Form.Item>
                          <Checkbox
                            indeterminate={fields.ccpaUS === undefined}
                            id="ccpaUS"
                            onChange={event => handlePrivacyModesChanges('ccpaUS', event)}
                            checked={fields.ccpaUS}
                          >
                            Target entire U.S.?
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span="24">
                        <Form.Item>
                          <Checkbox
                            indeterminate={fields.uspLspact === undefined}
                            id="ccpaLSPA"
                            onChange={event => handlePrivacyModesChanges('uspLspact', event)}
                            checked={fields.uspLspact}
                          >
                            IAB LSPA signed?
                            <Tooltip title={(
                              <>
                                Check with your DPO whether your company has signed the &nbsp;
                                <a href="https://www.iabprivacy.com/" target="_blank" rel="noopener noreferrer">
                                  Limited Services Provider Agreement
                                </a>
                                                                  &nbsp;as part of the&nbsp;
                                <a
                                  href="https://www.iab.com/blog/ccpa-compliance-framework/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  IAB CCPA Compliance Framework
                                </a>
                                .If you aren’t sure, leave this field unchecked
                                - this will not impact your usage of the CCPA opt-out signal.
                              </>
                            )}
                            >
                              <span>
                                                                  &nbsp;
                                <FontAwesomeIcon icon={faQuestionCircle} />
                              </span>
                            </Tooltip>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )}
              </>
            )
          }
          {
            fields.cmpVersion && isGBCSupported(fields.cmpVersion) && (
              <Col span="24">
                <GBCForm
                  setFieldsValue={setFieldsValue}
                  validateFields={validateFields}
                  getFieldValue={getFieldValue}
                  handleChange={handleChange}
                  values={fields}
                  purposesData={gbcPurposesData}
                  propertyType="bulk-update"
                />
              </Col>
            )
          }
        </Row>
      </CardContent>
    </Card>
  );
};

export default GeneralSettings;
